<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/project' }">
        <app-i18n code="entities.project.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item v-if="!findLoading">{{
        this.projectID
      }}</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="app-content-page">
      <div
        class="app-page-spinner"
        v-if="findLoading"
        v-loading="findLoading"
      ></div>
      <div style="max-width: 100%; max-height: 100%">
        <el-steps :active="active" simple finish-status="success">
          <el-step
            title="Create"
            @click.native="navigateToStep(projectStepEnum.create)"
          ></el-step>
          <el-step
            title="Release"
            @click.native="navigateToStep(projectStepEnum.release)"
          ></el-step>
          <el-step
            style="word-break: break-word"
            title="Funding"
            @click.native="navigateToStep(projectStepEnum.funding)"
          ></el-step>
          <el-step
            style="word-break: break-word"
            title="Gantt"
            @click.native="navigateToStep(projectStepEnum.gantt)"
          ></el-step>
          <el-step
            title="Implement"
            @click.native="
              navigateToStep(projectStepEnum.implementation)
            "
          ></el-step>
          <el-step
            title="Reports"
            @click.native="navigateToStep(projectStepEnum.report)"
          ></el-step>
          <el-step
            title="Complete"
            @click.native="navigateToStep(projectStepEnum.completion)"
          ></el-step>
        </el-steps>
        <router-view v-if="!findLoading"></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { routerAsync } from '@/app-module';
import ProjectStepEnum from '@/shared/enums/projectStep';

export default {
  name: 'app-project-view-page',

  props: ['id'],

  data() {
    return {
      active: 0,
      projectStepEnum: ProjectStepEnum.values,
      projectStepIds: ProjectStepEnum.ids,
    };
  },

  computed: {
    ...mapGetters({
      record: 'project/form/record',
      findLoading: 'project/form/findLoading',
      saveLoading: 'project/form/saveLoading',
      projectStep: 'project/form/step',
      projectID: 'project/form/projectID',
    }),

    isEditing() {
      return !!this.id;
    },

    stepFinishStatus(index) {
      if (this.projectStepId > index) {
        return 'success';
      }
      return 'process';
    },
  },

  async created() {
    await this.doFind(this.id);
    this.navigateToStep(this.projectStep);
  },

  methods: {
    ...mapActions({
      doFind: 'project/form/doFind',
      doNew: 'project/form/doNew',
      doUpdate: 'project/form/doUpdate',
      doCreate: 'project/form/doCreate',
    }),

    navigateToStep(step) {
      step = this.projectStepIds[step];
      const projectStepId = this.projectStepIds[this.projectStep];

      if (step > projectStepId) {
        return;
      }

      switch (step) {
        case 0:
          this.navigateToCreate();
          break;
        case 1:
          this.navigateToRelease();
          break;
        case 2:
          this.navigateToProjectFunding();
          break;
        case 3:
          this.navigateToProjectGantt();
          break;
        case 4:
          this.navigateToImplementation();
          break;
        case 5:
          this.navigateToReport();
          break;
        case 6:
          this.navigateToCompletion();
          break;
        default:
          this.navigateToCreate();
      }
    },

    navigateToCreate() {
      this.active = 0;
      this.$router.replace({
        name: 'projectCreateView',
      });
    },

    navigateToRelease() {
      this.active = 1;
      this.$router.replace({
        name: 'projectReleaseView',
      });
    },

    navigateToProjectFunding() {
      this.active = 2;
      this.$router.replace({
        name: 'projectFundingView',
      });
    },

    navigateToProjectGantt() {
      this.active = 3;
      this.$router.replace({
        name: 'projectGanttView',
      });
    },

    navigateToImplementation() {
      this.active = 4;
      this.$router.replace({
        name: 'projectImplementationView',
      });
    },

    navigateToReport() {
      this.active = 5;
      this.$router.replace({
        name: 'projectReportView',
      });
    },

    navigateToCompletion() {
      if (this.active != 6) {
        this.active = 6;
        this.$router.replace({
          name: 'projectCompletionView',
        });
      }
    },

    doCancel() {
      routerAsync().replace('/project');
    },
  },
};
</script>

<style scoped>
</style>
